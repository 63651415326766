import {
  creatorAdminBaseUrl,
  accountsBaseUrl,
  storefrontBaseUrl,
} from '-/leap-auth';

import type { DataFunctionArgs } from '@remix-run/cloudflare';
import type { IdentityProvider } from '-/leap-auth';
import type { BlockTypes, TemplateTypes } from '-/leap-models';

export const loginUrl = (args: DataFunctionArgs) =>
  `${accountsBaseUrl(args)}/accounts/login`;

export const logoutPath = () => '/accounts/logout';

export const signUpUrl = (args: DataFunctionArgs) =>
  `${accountsBaseUrl(args)}/accounts/signup`;

export const settingsGeneralUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/manage/settings/general`;
export const settingsBillingUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/manage/settings/billing`;

export const settingsAccountsConnectCallbackUrl = (
  args: DataFunctionArgs,
  provider: IdentityProvider
) =>
  `${creatorAdminBaseUrl(
    args
  )}/manage/settings/accounts/${provider.toLocaleLowerCase()}/callback`;

export const calendarAvailabilityUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/manage/calendar/availability`;

export const rootPath = () => '/manage/';
export const reportsPath = () => '/manage/reports';
export const storePath = () => '/manage/store';
export const newEmailFlowPath = () => '/manage/emails/flows/new';
export const newTemplatePath = (kind: string, template: string) =>
  `/manage/emails/${kind}/${template}/new`;
export const emailsPath = () => '/manage/emails';
export const emailFlowsPath = () => '/manage/emails/flows';
export const emailFlowPath = (id: string) => `/manage/emails/flows/${id}`;
export const emailFlowUrl = (args: DataFunctionArgs, id: string) =>
  `${creatorAdminBaseUrl(args)}/manage/emails/flows/${id}`;
export const emailBroadcastsPath = () => '/manage/emails/broadcasts';
export const emailBroadcastPath = (id: string) =>
  `/manage/emails/broadcasts/${id}`;
export const emailBroadcastNewPath = () => '/manage/emails/broadcasts/new';
export const calendarPath = () => '/manage/calendar';
export const calendarEventsPath = (queryParam?: string) =>
  `/manage/calendar/events${queryParam ? `?filter=${queryParam}` : ''}`;
export const calendarAvailabilityPath = () => '/manage/calendar/availability';
export const contactsPath = () => '/manage/contacts';
export const contactPath = (id: string) => `/manage/contacts/${id}`;
export const contactUrl = (args: DataFunctionArgs, id: string) =>
  `${creatorAdminBaseUrl(args)}/manage/contacts/${id}`;
export const homePath = () => '/manage/home';
export const productsPath = () => '/manage/products';
export const newProductsPath = () => '/manage/products/new';
export const generateProductsPath = () => '/manage/products/generate';
export const editBlockPath = (id: string) => `/manage/products/${id}/edit`;
export const editBlockUrl = (args: DataFunctionArgs, id: string) =>
  `${creatorAdminBaseUrl(args)}/manage/products/${id}`;
export const newBlockPath = (type: string) => `/manage/products/${type}/new`;
export const createBlockPath = (type: BlockTypes) =>
  `/manage/products/${type}/new`;
export const generateBlockPath = (type: TemplateTypes) =>
  `/manage/products/${type}/generate`;
export const generateLandingPagePath = (type: string) =>
  `/manage/products/${type}/generate-landing-page`;
export const settingsPath = () => '/manage/settings';
export const settingsBrandPath = () => '/manage/settings/theme';
export const settingsPaymentPath = () => '/manage/settings/payments';
export const settingsGeneralPath = () => '/manage/settings/general';
export const settingsBillingPath = () => '/manage/settings/billing';
export const settingsPaymentConnectStripePath = () =>
  '/manage/settings/payments/connect-stripe';
export const settingsAccountsPath = () => '/manage/settings/accounts';
export const settingsZapierPath = () => '/manage/settings/zapier';
export const invitationsPath = () => '/manage/invitations';
export const sendInvitationsPath = () => '/manage/invitations/new';
export const onboardingWelcome = () => '/manage/onboarding/welcome';
export const onboardingGettingStartedPath = () =>
  '/manage/onboarding/getting-started';
export const onboardingConnectSocialAccountPath = () =>
  '/manage/onboarding/connect-social-account';
export const onboardingWhatIsNextPath = () => '/manage/onboarding/what-is-next';
export const onboardingSetUpStoreRootPath = () =>
  '/manage/onboarding/set-up-store';
export const onboardingSetUpStoreProfilePath = () =>
  '/manage/onboarding/set-up-store/profile';
export const onboardingSetUpStoreThemePath = () =>
  '/manage/onboarding/set-up-store/theme';
export const onboardingSetUpStoreSharePath = () =>
  '/manage/onboarding/set-up-store/share';

export const gettingStartedWelcome = () => '/manage/getting-started/welcome';
export const gettingStartedConnectSocialAccountPath = () =>
  '/manage/getting-started/connect-social-account';
export const gettingStartedWhatIsNextPath = () =>
  '/manage/getting-started/what-is-next';
export const gettingStartedSetUpStoreRootPath = () =>
  '/manage/getting-started/set-up-store';
export const gettingStartedSetUpStoreThemePath = () =>
  '/manage/getting-started/set-up-store/theme';
export const gettingStartedSetUpStoreSharePath = () =>
  '/manage/getting-started/set-up-store/share';
export const salesReportPath = () => '/manage/api/salesReport';
export const leadsReportPath = () => '/manage/api/leadsReport';
export const contactsReportPath = () => '/manage/api/contactsReport';
export const assistantPath = () => '/manage/assistant';
export const referralsPath = () => '/manage/referrals';

export const productLandingPageUrl = (
  creatorStorefrontUrl: string,
  slug: string
) => `${creatorStorefrontUrl}/${slug}`;

export const updateEmailPath = () => '/manage/api/update-email';
export const resendEmailConfirmationPath = () =>
  '/manage/api/resend-email-confirmation';
export const deleteAccountPath = () => '/manage/api/delete-account';

export const confirmEmailUrl = (args: DataFunctionArgs) =>
  `${accountsBaseUrl(args)}/accounts/confirm-email`;

export const creatorStorefrontUrl = (args: DataFunctionArgs, handle: string) =>
  `${storefrontBaseUrl(args)}/@${handle}`;

export const previewLeapProductUrl = (
  args: DataFunctionArgs,
  handle: string,
  productSlug: string
) => `${creatorStorefrontUrl(args, handle)}/${productSlug}/preview`;

export const adminPanelPath = () => '/admin/creators';

export const isBroadcastPath = (pathname: string) =>
  pathname.split('/').includes('broadcasts');

export const analyticsPath = () => '/manage/analytics';
